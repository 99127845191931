import { TeamImage } from "../assets";

const AboutUsSection: React.FC = () => {
  return (
    <div
      id="aboutus"
      className="flex flex-col w-full items-center md:items-start justify-center gap-4 pt-20 md:pt-0 pb-20 px-8 md:px-16"
    >
      {/* <div className="flex flex-col md:flex-row items-center justify-between w-full md:border-t md:pt-16"> */}
      <div className="flex flex-col md:flex-row items-center justify-between w-full md:pt-16">
        <div className="flex-1 mb-4 md:mb-0">
          <img
            src={TeamImage}
            alt="About Us"
            className="w-full h-auto object-cover rounded-lg"
          />
        </div>
        <div className="flex-1 text-left md:pl-8">
          <h2
            style={{ color: "#454C8C" }}
            className="text-2xl md:text-3xl font-bold text-slate-800 mb-4"
          >
            Our Story
          </h2>
          <p className="text-base md:text-lg font-light text-slate-600">
            We’re a team of software engineers and AI enthusiasts united by a
            shared mission: to turn the vast, unstructured landscape of the
            internet into accessible, actionable data. With over a decade of
            combined experience in software engineering—and a track record of
            building web-scraping and LLM-powered applications—we came together
            as graduate students at Carnegie Mellon University to study AI and
            harness its transformative power. Our product, Gumbo, is designed to
            make sense of publicly available data like never before—delivering
            insights that matter, right when businesses need them most.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
