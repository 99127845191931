import { Typography } from "antd";
import {
  ExcelsheetWithoutButton,
  HeroExcelsheet,
  HowItWorksDesktop,
  HowItWorksImage,
  RawInternet,
  WhatWeDoLeftImage,
} from "../assets";
import WhatWeDoLeft from "./what-we-do-left";
import { ArrowDownOutlined } from "@ant-design/icons";

const HowItWorks: React.FC = () => {
  return (
    <div
      id="howitworks"
      // className="what-we-do-left w-full flex flex-col gap-2 items-start max-w-lg md:px-4 pt-8 xl:px-0 xl:pr-16 transition-all duration-300"
      className="flex flex-col w-full items-center md:items-start justify-center gap-4 py-20 px-8 md:px-16"
    >
      <h2
        style={{ color: "#454C8C" }}
        className="text-2xl md:text-3xl font-bold text-slate-800 mb-4"
      >
        How it works
      </h2>
      <img
        src={HowItWorksDesktop}
        alt="how it works"
        className="hidden md:flex w-full h-auto object-cover"
      />{" "}
      <div className="md:hidden text-base pr-2 font-poppins font-medium py-2 text-start">
        1. Tell us what data you want
      </div>
      <img
        src={WhatWeDoLeftImage}
        alt="how it works"
        className="flex md:hidden w-full h-auto object-cover"
      />
      <div className="md:hidden text-base pr-2 font-medium py-2 text-start">
        2. We intelligently scrape the web
      </div>
      <img
        src={RawInternet}
        alt="how it works"
        className="flex md:hidden w-full h-auto object-cover"
      />
      <div className="md:hidden text-base pr-2 font-medium py-2 text-start">
        3. We use LLMs to extract structured data
      </div>
      <img
        src={ExcelsheetWithoutButton}
        alt="how it works"
        className="flex md:hidden w-full h-auto object-cover"
      />
    </div>
  );
};

export default HowItWorks;
