import "./App.css";
import AboutUsSection from "./landing/about-us";
import EmailSection from "./landing/email-section";
import HeroSection from "./landing/hero-section";
import WhatWeDoLeft from "./landing/what-we-do-left";
import WhatWeDoSection from "./landing/what-we-do-section";
import HowItWorks from "./landing/how-it-works";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#454C8C",
        },
      }}
    >
      <div className="App flex w-full justify-center md:pb-60">
        <div className=" justify-center max-w-screen-xl">
          <div className="min-h-screen flex flex-col items-center justify-center">
            <div className="flex flex-row w-full justify-center md:justify-end p-8 px-16">
              <div className="flex flex-row gap-4">
                <span
                  className="font-light cursor-pointer"
                  onClick={() => {
                    const element = document.getElementById("howitworks");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Product
                </span>
                <span
                  className="font-light cursor-pointer"
                  onClick={() => {
                    const element = document.getElementById("aboutus");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  About Us
                </span>
                <span
                  className="font-light cursor-pointer text-gumbo-green"
                  onClick={() => {
                    const element = document.getElementById("getlist");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Sign Up
                </span>
              </div>
            </div>
            <main className="flex-grow w-full py-4">
              <HeroSection />
              <div style={{backgroundColor: "#454C8C"}} className="border-t w-full mt-8 md:mt-0 h-[2px]" />
              <HowItWorks />
              <div style={{backgroundColor: "#454C8C"}} className="border-t w-full mt-8 md:mt-0 h-[2px]" />
              <AboutUsSection />
              <div style={{backgroundColor: "#454C8C"}} className="border-t w-full mt-8 md:mt-0 h-[2px]" />
              <EmailSection />
            </main>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default App;
