import React from "react";
import { HeroExcelsheet } from "../assets";

const HeroSection = () => {
  return (
    <div className="flex flex-col md:flex-row px-8 md:px-16 gap-4 md:gap-8 xl:gap-16 items-center justify-between py-0 md:py-8 xl:py-4 xl:h-[65dvh]">
      <div className="flex-1 text-left mb-8 md:mb-0">
        <h1 style={{ color: '#454C8C'}} className="text-4xl md:text-5xl xl:text-7xl text-center md:text-start font-extrabold mb-8 text-slate-800 font-poppins">
          GUMBO
        </h1>
        {/* <h4 className="text-base md:text-lg xl:text-xl  font-light mb-8">
          Data you need, when you need it!
        </h4> */}
        <h4 className="text-base md:text-lg xl:text-xl font-light max-w-md">
          Transform the internet into structured, actionable data with a few
          simple clicks
        </h4>
      </div>
      <div className="flex-1 items-center justify-center">
        <div
          className="font-light cursor-pointer"
          onClick={() => {
            const element = document.getElementById("getlist");

            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          <img
            src={HeroExcelsheet}
            alt="Hero Image"
            className="w-full h-auto object-cover cursor-pointer"
          />
        </div>
        <div className="py-4 text-center text-xs">
          A Gumbo-generated spreadsheet with 2k+ venture capital funds and 16k+ contacts
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
