import { Button, Checkbox, Input, Image } from "antd";
import React, { useState } from "react";
import { GumboLogo } from "../assets";

const EmailSection: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const res = await fetch("/api/submitForm", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    });

    if (formData.email !== "" || formData.name !== "") {
      setFormData({
        name: "",
        email: "",
        company: "",
        message: "",
      });
      if (isChecked) {
        window.location.href = "/vc_fund_data.xlsx";
      }
    }
    setResponseMessage("Thanks for submitting!");
  };
  const [isChecked, setIsChecked] = useState(true);

  return (
    <div
      id="getlist"
      className="flex flex-col items-start justify-center py-20 px-8 md:px-16 max-w-screen-xl"
    >
      {/* <div className="hidden md:flex w-full border-t-2 py-4"></div> */}
      <div className="flex flex-col md:flex-row w-full gap-4 md:gap-16 justify-between">
        <div className="md:w-3/4">
          <div style={{ color: '#454C8C'}} className="text-2xl text-start font-bold md:text-3xl">
            Releasing to Beta users in early 2025
          </div>
          <div className="text-base pr-2 font-medium py-2 text-start">
            Sign up for access to discounted datasets
          </div>
          <form onSubmit={handleSubmit}>
            <Input
              name="name"
              placeholder="Name"
              onChange={handleChange}
              required
              value={formData.name}
              className="mb-2"
            />
            <Input
              name="email"
              placeholder="Email"
              type="email"
              onChange={handleChange}
              required
              value={formData.email}
              className="mb-2"
            />
            <Input
              name="company"
              placeholder="Company (Optional)"
              onChange={handleChange}
              value={formData.company}
              className="mb-2"
            />
            <Input.TextArea
              name="message"
              placeholder="Describe a specific dataset that you would find useful (Optional)"
              onChange={handleChange}
              className="mb-2"
              maxLength={500}
              value={formData.message}
              showCount
            />
            <Checkbox
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              className="pb-4"
            >
              Download the VC data excelsheet {"  "}
              {/* <FileExcelOutlined className="text-gumbo-green" /> */}
            </Checkbox>
            <div className="flex flex-col md:flex-row justify-start items-start gap-4">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              {responseMessage && <div className="py-2">{responseMessage}</div>}
            </div>
          </form>
        </div>
        <div className="flex flex-col items-center justify-center text-2xl md:w-1/4 text-start font-bold md:text-3xl">
          <img
            src={GumboLogo}
            alt="Gumbo Logo"
            className="w-full h-auto max-w-64 object-cover cursor-pointer"
          />
          {/* <div className="flex flex-row text-xl font-light items-start">
            Data made delicious!
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default EmailSection;
